.modify-category-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.modify-category-form {
  margin-bottom: 20px;
}

.modify-category-form input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.category-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.search-container {
  margin-bottom: 20px;
}

.product-category-selection {
  max-height: 300px;
  overflow-y: auto;
}

.product-category-item {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-bottom: 8px;
  border-radius: 4px;
}

.category-title {
  font-weight: bold;
}

.no-category-results {
  color: red;
}

.selected-category-container {
  margin-top: 20px;
  padding: 10px;
  background-color: #eaf2ff;
  border-radius: 8px;
}

.selected-category-item {
  padding: 5px 0;
}

.category-response-message {
  margin-top: 10px;
  color: green;
}
  