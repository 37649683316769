/* AboutUs.css */
.about-us {
  padding: 60px 20px 0;
  text-align: center;
}

.about-title {
  font-size: 5vh;
  font-weight: bold;
  margin-bottom: 3vh;
  color: #2C3360;
}

.about-description {
  font-size: 2vh;
  line-height: 1.8;
  margin-bottom: 5vh;
  color: #444;
}

.btn-about-us{
  padding: 0.7vh 1.35vh;
  font-size: 1.7vh;
  margin-top: 5vh;
  margin-bottom: 0;
  background-color: #2C3360;
  width: 80%;
  max-width: 25vh;
  border: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.558); 
  transition: all 0.3s ease;
}

.btn-about-us:hover{
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.71); 
  transform: translateY(-2px);
}

.reputation-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.reputation-image {
  width: auto;
  height: 35vh;
  border-radius: 1vh;
  transition: transform 0.3s ease;
}

.reputation-text {
  margin-top: 5vh;
  padding: 0 10vh;
  font-size: 3.5vh;
  margin-bottom: 0;
  font-weight: bold;
  color: #2C3360;
}
  
/* RESPONSIVE */
@media (max-width: 1000px) {
  .reputation-text {
    margin-top: 7vh;
    padding: 0;
    font-size: 3.2vh;
    font-weight: bold;
    color: #2C3360;
  }

  .reputation-section {
    gap: 2vh;
  }
}