.payment-info-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 10vh;
    width: 100%;
}

.payment-info-cards-container h4 {
    color: #2C3360;
    margin-bottom: 15px;
    font-size: 2vh;
}

.payment-icons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
}

.payment-icon {
    position: relative;
    font-size: 1.7vh;
    color: #2C3360;
    padding: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.payment-icon:hover {
    transform: scale(1.1);
    color: #1A837C;
}

.payment-info-card {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    padding: 15px;
    background-color: #FFFDF6;
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    text-align: left;
    z-index: 10;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0;
    visibility: hidden;
}

.payment-icon:hover .payment-info-card {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(-2vh);
}

.payment-info-card h5 {
    margin: 0;
    font-size: 1.7vh;
    color: #2C3360;
}

.payment-info-card p {
    margin: 10px 0 0;
    font-size: 1.5vh;
    color: #6C7856;
}

@media (max-width: 768px) {
    .payment-info-cards-container {
        margin: 2vh 0 0;
        text-align: center;
        padding: 0 5vh;
    }

    .payment-icons {
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
    }

    .payment-icon {
        font-size: 1.5vh;
        margin-bottom: 20px;
    }

    /* Mostrar siempre el cartel informativo */
    .payment-info-card {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        opacity: 1;
        visibility: visible;
        width: 90%;
        max-width: 300px;
        margin-top: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        padding: 10px;
    }

    .payment-info-card h5 {
        font-size: 1.5vh;
    }

    .payment-info-cards-container h4 {
        width: 100%;
        font-size: 1.6vh;
        padding-left: 0.5vh;
    }

    .payment-info-card p {
        font-size: 1.2vh;
    }

    .payment-icon:hover .payment-info-card {
        transform: none;
    }
    
}