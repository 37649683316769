.shapedividers_com-3946 {
  overflow: hidden;
  position: relative;

}

.shapedividers_com-3946::before { 
  content: '';
  font-family: 'shape divider from ShapeDividers.com';
  position: absolute;
  pointer-events: none;
  background-repeat: no-repeat;
  bottom: -0.1vw;
  left: -0.1vw;
  right: -0.1vw;
  top: -0.1vw; 
  background-size: 100% 90px;
  background-position: 50% 0%;  
  background-image: url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23fbd8c2"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23fbd8c2"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23fbd8c2"/></svg>');
}

@media (min-width: 2100px) {
  .shapedividers_com-3946::before {
    background-size: 100% calc(2vw + 90px);
  }
}

.footer {
  background-color: #1D1D1D;
  color: white;
  padding-top: 10vh;
  text-align: center;
  margin: 0;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  margin: 2vh auto;
  padding-top: 3vh;
}

.footer-section {
  flex: 1;
  width: 33.3%;
  max-width: 200px;
}

.logo{
  width: 100%;
  height: 100%;
}

.footer-section h4 {
  margin-bottom: 15px;
  font-size: 1rem;
}

.footer-section p, .footer-section a {
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.utils-link a{
  color: white;
}

.footer-section a:hover {
  color: #46572A;
  color: #2C3360;
}

.footer-section ul{
  padding: 2vh 0;
  list-style: none;
  font-size: 0.8rem;
}

.footer-section ul li{
  padding: 0.5vh 0;
}



.social-icons-footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  max-height: 15vh;
}

.social-icons-footer a {
  background-color: transparent;
  border-radius: 50%;
  text-decoration: none;
  display: inline-block;
  font-size: 2vh;
  width: 60px;
  height: 60px;
  line-height: 0.5vh;
  text-align: center;
  transition: background-color 0.3s ease;
}

.social-icons-footer a:hover {
  background-color: transparent;
  color: #2C3360;
}

.icons-wsp{
  color: #25D366;
}

.icons-ig{
  color: #E1306C;
}

.icons-tk{
  color: #FFFFFF;
}

.icons-ml{
  color: #FFE600;
}

@media (max-width: 600px) {
  .social-icons-footer{
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}