/* featuredProducts.css */

h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  input[type='text'] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .products-list-admin {
    font-family: 'Lora', serif;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
  }
  
  .product-item {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .product-item:hover {
    background-color: #f9f9f9;
  }
  
  .product-item.selected {
    background-color: #007bff;
    color: #fff;
  }
  
  .selected-products-container {
    margin-top: 20px;
  }
  
  .selected-products-container h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .ul-featured {
    list-style: none;
    padding: 0;
    padding-left: 10px;
  }
  
  .ul-featured li {
    margin-bottom: 5px;
  }