.modify-group-container {
  margin: 0 auto;
  padding: 2vh;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.modify-group-title {
  text-align: center;
  font-size: 2.4vh;
  color: #333;
}

.modify-group-search {
  width: 100%;
  padding: 1vh;
  margin: 3.2vh 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1.8vh;
}

.modify-group-textarea {
  width: 100%;
  height: 25vh;
  padding: 1vh;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1.8vh;
  resize: none;
}

.modify-group-button {
  display: block;
  width: 100%;
  padding: 1vh;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.8vh;
  letter-spacing: 0.6px;
  font-weight: bold;
  cursor: pointer;
}

.selected-products-container{
  padding: 2vh;
  border: 0.2vh solid #ccc;
  border-radius: 1vh;
  background: #fff;
}

.selected-products-subcontainer {
  max-height: 40vh; 
  overflow-y: auto; 
  display: flex;
  flex-wrap: wrap; 
  justify-content: center; 
  gap: 1vh; 
}

.selected-products-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1vh;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: center;
}

.modify-group-button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

.modify-group-list {
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.modify-group-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.modify-group-checkbox {
  width: 1.8vh;
  height: 1.8vh;
}

.modify-group-product-title {
  font-weight: bold;
  color: #333;
  min-width: 20vh;
}

.modify-group-product-desc {
  font-style: italic;
  color: #555;
  max-height: 10vh;
  overflow: hidden;
  font-size: 1.6vh;
  display: block;
}

.modify-group-no-results {
  text-align: center;
  font-size: 1.8vh;
  color: #777;
  margin-top: 10px;
}

.selected-product-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8vh;
  width: 18vw; /* Controla el ancho de cada elemento */
  max-width: 20vw;
  background-color: #f0f0f0;
  border-radius: 0.5vh;
  font-size: 2vh;
  box-shadow: 0 0.3vh 0.6vh rgba(0, 0, 0, 0.1);
}

.selected-product-title {
  font-size: 1.6vh;
  flex-grow: 1;
  text-align: left;
  font-weight: bold;
}

.remove-selected-btn{
  margin-left: 1vh;
  border: none;
}

