.titles-featured-container{
  display: flex;
  flex-direction: row;
  gap: 3vh;
}

.titles-featured-container p{
  font-size: 1.5vh;
  color: white; 
  text-decoration: none;
  font-weight: 500;
  background-color: #2C3360; /* Fondo para resaltar el enlace */
  padding: 0.8vh 1.8vh;
  border-radius: 5px;
  margin-top: 0.7vh;
  transition: all 0.3s ease;
}

.titles-featured-container p:hover {
  color: white; 
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.598); 
  transform: translateY(-1px);
}

.titleh2 {
  padding-top: 1.3vh;
  font-family: "Montserrat", sans-serif;
  font-size: 2.50vh;
  color: #333;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 5vh;
  text-align: start;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

@media (max-width: 900px) {
  .titles-featured-container p{
    display: none;
  }
}

@media (max-width: 500px) {
  .titleh2 {
    font-size: 2.2vh;
  }
  
}