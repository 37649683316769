
.containerTotal {
  background-color: #2C3360;
  color: #000000;
  margin: 0;
  padding: 0;
  width: 100%;
  z-index: 2000;
  font-family: "Roboto Condensed", sans-serif;
  
}
   
.containerCategory{
  width: 33%;
  height: auto;
  z-index: 2000;
  display: flex;
  justify-content: center;
}

.containerSection{
  width: 100%;
  z-index: 2000;
  margin: 0;
  padding: 0;
  position: sticky; 
  top: 0; 
  z-index: 2000;
}

.containerNav{
  width: 100%;
  z-index: 2000;
  display: flex;
  justify-content: space-between;
  padding: 0px 10rem;
  z-index: 1000;
  border-bottom: 1px solid #6C7856;
}

.navRightSide{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 50px;
  align-items: center;
  align-self: center;
  align-content: center;
  flex-direction: row;
}

.containerNav nav{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo {
  width: 50%;
  display: flex;
  justify-content: start;
  padding: 0.7rem 0.7rem 0.7rem 0.7rem;
}

.logo img{
  width: 11vh;
  height: 11hv;
  border-radius: 0.4vh;
}

.nav-links{
  width: 100px;
  margin-left: 50px;
}

.nav-links ul{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
  gap: 5px;
  border-left: 1px solid #46572A;
  margin-left: 1rem;
}

.menu-toggle div{
  color: white;
}

.btn{
  max-width: 5rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
}

.btn h3{
  font-size: 1rem;
}

.nav-links li {
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  align-content: center;
  list-style: none;
}

.nav-links a,
.nav-links li button,
.nav-links {
  color: #46572A;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  font-size: 1.5rem;

}

.nav-links a:hover,
.nav-links button:hover {
  color: #4682B4; 
}

.notification {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  font-size: 1rem;
  transform: translatey(1.7rem);
  color: #FAB71E;
  font-weight: bolder;
  text-align: center;
  line-height: 25px;
}

.notification::after {
  content: attr(data-number); 
  position: absolute;
  color: inherit;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0);
}

.cart-icon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
  min-width: 3rem;
  font-size: 3vh;
  height: 5rem;
  color: whitesmoke;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  align-content: center;
}

.cart-icon:hover{
  color: #FAB71E;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.drawer-nav-container{
  display: none;
}

@media (max-width: 1000px) {
  
  .drawer-nav-container{
    display: block;
    height: 100%;
    align-content: center;
    width: 100%;
  }

  .containerCategory{
    display: none;
  }

  .containerNav{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 0px 1rem;
  }

  .containerNav nav{
    width: 100%;
    justify-content: space-evenly;
  }

  .navRightSide{
    width: 33%;
    justify-content: center;
    height: 100%;
    gap: auto;
    align-content: center;
  }

  .nav-links{
    width: 100%;
  }

  .itemsRight{
    display: block;
  }

  .logo {
    width: 33%;
    height: 100%;
    align-content: center;
    justify-content: center;
    padding-bottom: 0.7rem;
  }

  .nav-links ul{
    border: none;
  }

  .itemLogin{
    display: none;
  }

  .nav-links .btn{
    display: none;
  }

  .cart-icon {
    font-size: 3.5vh;
    transform: translatey(2vh);
    display: block;
    justify-content: space-evenly;
    width: auto;
    height: 100%;
    align-content: center;
    align-items: center;
  }

  .nav-links{
    width: 100%;
    height: 100%;
  }
  

  .nav-links ul{
    align-content: center;
    justify-content: end;
    height: 100%;
    width: 100%;
    gap: 0;
    margin-right: 0;
  }

  .notification {
    transform: translatey(-1rem);
  }

}