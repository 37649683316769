
.recommended-product-card {
  flex: 2 0 auto;
  cursor: alias;
  border: none;
  padding: 0.6vh 0.6vh 0;
  border-radius: 0.25rem;
  max-width: 35vh;
  width: 30vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.content-price-title{
  width: 100%;
  padding: 0.5vh 0;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor:default;
}

.content-price-card{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: 500;
  height: auto;
  color: black;
  cursor: text;
  padding: 0;
  font-size: 1.8vh;
  padding: 0.5vh 0.1vh 0;
}

.currency-symbol {
  height: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: inherit; 
  margin-right: 0.1vh;
}

.currency-symbol::after {
  content: '\00a0'; 
}

.recommended-product-info {
  width: 100%;
  padding: 6px 0 0 0;
  text-align: center;
}

.container-title-card{
  display: flex;
  flex-direction: column;
  padding: 0 0.1vh;
}

.recommended-product-title {
  text-align: start;
  display: inline-flex;
  align-items: start;
  font-size: 1.8vh;
  color: black;
  transition: color 0.3s;
  cursor: pointer;
}

.galleria-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.galleria-item-card img {
  width: 100%;
  height: auto;
  transition: transform 1s ease;
  background-image: linear-gradient(45deg, #6D83F2, #A18CD1);
  background-size: 200% 200%;
  animation: gradientTransition 10s ease infinite;
}


.galleria-container:hover .galleria-item-card img {
  transform: scale(1.05);
}

.galleria-item-card img {
  width: 100%;
  height: 29.5vh;
  background-size: cover;
  width: 100%;
  display: block;
  object-fit: cover;
  background-repeat: no-repeat;
  border-radius: 0.15rem;
  z-index: 900;
}

.p-galleria-item-prev,
.p-galleria-item-next {
    height: min-content;
    filter: drop-shadow(
      2px 2px 1px
      #6C7856
    );
    top: 45%;
    width: 2vh;
    margin: 0;
    z-index: 900;
}

.p-galleria-thumbnail-prev-icon:hover{
  color: #444;
}

.p-galleria-thumbnail-next-icon:hover{
  color: #444;
}

@media (max-width: 1000px) {
  .recommended-product-card {
    flex: auto;
    padding: 0.6rem;
    overflow: hidden;
    border-radius: 0.25rem;
    height: auto;
    width: auto;
  }
  
  .recommended-product-image {
    width: 100%;
    height: 12rem;
  }
  
  .galleria-item-card img {
    max-width: 25vh;
    height: 29.5vh;
    background-size: cover;
    width: 100%;
    object-fit: cover;
  }

  .content-price-card{
    height: 100%;
    width: 100%;
    margin-bottom: -2vh;
    font-size: 1.5vh;
  }
  
  .recommended-product-info {
    padding: 0.5vh 0 0 0;
    text-align: center;
  }

  .recommended-product-title {
    font-size: 1.5vh;
    transition: color 0.3s;
  }
}

@media (max-width: 500px) {
  .galleria-item-card img {
    max-width: 25vh;
    max-height: 28vh;
    background-size: cover;
    width: 100%;
    object-fit: cover;
  }

  .p-galleria-item-prev,
  .p-galleria-item-next {
    z-index: 900;
  }

}