.add-product-form {
    display: flex;
    font-family: 'Lora', serif;
    flex-direction: column;
    gap: 15px;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: auto;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    width: 100%; /* Ajusta el contenedor al ancho disponible */
  }

  .group-url-name{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .form-label {
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }
  
  .form-input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1em;
    width: 100%; /* Asegura que el textarea ocupe todo el ancho del contenedor */
    box-sizing: border-box; /* Incluye el padding y el borde en el ancho total */
    resize: vertical; /* Permite al usuario ajustar la altura, pero no el ancho */
  }
  
  .form-input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;
    font-size: 1em;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .image-previews {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
  
  .image-previews img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  