/* Container */
.modify-prices-container {
  max-width: 100%;
  margin: 2vh auto;
  padding: 2vh;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Heading styles */
.modify-prices-container h1 {
  font-size: 2.6vh;
  margin-bottom: 1.5rem;
  color: #333;
  text-align: center;
}

/* Form styles */
.modify-prices-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  align-items: center;
  margin-bottom: 2rem;
}

.modify-prices-form label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  flex: 1;
}

.modify-prices-form input[type="number"],
.modify-prices-form select {
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  width: 100%;
}

.modify-prices-form input[type="checkbox"] {
  margin-right: 0.5rem;
}

.modify-prices-form .checkbox-group {
  display: flex;
  align-items: center;
}

/* Spinner styles */
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner:after {
  content: '';
  width: 40px;
  height: 40px;
  border: 4px solid #007bff;
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.div-span{
  display: flex;
  flex-direction: column;
}

.div-search-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.div-search-container label{
  width: 25vw;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Product list styles */
.product-selection-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 1.5rem;
  background-color: #fafafa;
}

.product-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5vh 0.6vh;
  border-bottom: 1px solid #eee;
}

.product-item label {
  display: flex;
  align-items: center;
}

.product-item input[type="checkbox"] {
  margin-right: 0.5rem;
}

.product-item:last-child {
  border-bottom: none;
}

/* Selected products list */
.selected-products-container {
  margin-top: 2rem;
}

.selected-products-container h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #007bff;
}

.selected-product {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-bottom: 0.5rem;
  background-color: #e6f7ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-product span {
  font-size: 0.9rem;
  color: #333;
}

/* Button styles */
.modify-prices-container button {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 1.5rem auto;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modify-prices-container button:hover {
  background-color: #0056b3;
}


/* Responsive styles */
@media (max-width: 600px) {
  .modify-prices-form {
    flex-direction: column;
  }

  .modify-prices-form label {
    width: 100%;
  }
}
