.modify-prices-container {
    width: 100%;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modify-prices-container h2 {
    font-size: 2.4vh;
    text-align: center;
    margin-bottom: 2vh;
    color: #333;
}

.form-group {
    margin-bottom: 1.5vh;
}

.label-modify-coin{
    display: block;
    font-size: 1.4vh;
    color: #555;
    margin-bottom: 1.4vh;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 1vh;
    font-size: 1.6vh;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-group input[type="checkbox"] {
    width: auto;
    margin-right: 1vh;
}

.submit-btn {
    width: 100%;
    padding: 1vh;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.submit-btn:hover:not(:disabled) {
    background-color: #45a049;
}

.response-message {
    margin-top: 2vh;
    text-align: center;
    font-size: 16px;
    color: #333;
}

.note {
    font-size: 1.2vh;
    color: #777;
    margin-left: 5px;
}
