.categoriesAddContainer {
  width: 80vw;
  min-height: 80vh;
  margin: 5vh auto;
  padding: 2vh;
  background-color: #f9f9f9;
  border-radius: 1vh;
  box-shadow: 0 0.5vh 1vh rgba(0, 0, 0, 0.1);
}

.categoryAddTitle {
  font-size: 3vh;
  text-align: center;
  margin-bottom: 2vh;
  color: #333;
}

.modify-category-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2vh;
}

.modify-category-form label {
  font-size: 2vh;
  font-weight: bold;
}

.categoryInput,
.modify-category-form input {
  width: 60vw;
  height: 4vh;
  margin-top: 1vh;
  padding: 1vh;
  font-size: 2vh;
  border: 0.2vh solid #ccc;
  border-radius: 0.5vh;
}

.search-container {
  margin-bottom: 3vh;
}

.product-category-selection {
  max-height: 40vh;
  overflow-y: auto;
  padding: 1vh;
  border: 0.2vh solid #ddd;
  border-radius: 1vh;
  background: #fff;
}

.product-category-item {
  padding: 1vh;
  font-size: 1.8vh;
  display: flex;
  align-items: center;
}

.category-title {
  font-weight: bold;
  margin-left: 1vh;
}

.no-category-results {
  text-align: center;
  font-size: 2vh;
  color: #999;
}

.selected-category-container {
  margin-top: 3vh;
  padding: 2vh;
  background: #e6f7ff;
  border-radius: 1vh;
}

.selected-category-container h3{
  font-size: 2.4vh;
}

.selected-category-item {
  font-size: 1.8vh;
  padding: 1vh 0;
  display: flex;
  justify-content: space-between;
}

.btn-modify-category {
  display: block;
  width: 40vw;
  margin: 2vh auto;
  padding: 0.8vh 0.8vh 1vh;
  font-size: 1.8vh;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 1vh;
  cursor: pointer;
  transition: background 0.3s;
}

.btn-modify-category:hover {
  background-color: #0056b3;
}

.category-response-message {
  text-align: center;
  font-size: 1.8vh;
  margin-top: 2vh;
  color: #d9534f;
}

.category-spinner {
  width: 5vh;
  height: 5vh;
  border: 0.7vh solid #ccc;
  border-top: 0.7vh solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 5vh auto;
}

.subcontainer-label{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
