
.menu-toggle {
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1.5rem;
  height: 1.2rem;
  cursor: pointer;
  z-index: 1001; 
}

.menu-toggle .bar1,
.menu-toggle .bar2,
.menu-toggle .bar3 {
  width: 100%;
  height: 3px;
  background-color: #FFFDF6;
  transition: all 0.3s ease;
}

.menu-toggle.open .bar1 {
  transform: rotate(-45deg) translate(-5px, 5px);
}

.menu-toggle.open .bar2 {
  opacity: 0;
}

.menu-toggle.open .bar3 {
  transform: rotate(45deg) translate(-5px, -5px);
}


.drawer {
  position: fixed;
  left: -255px;
  width: 250px;
  height: 100%;
  top: 13.28vh;
  background-color: #F9F8F3;
  transition: left 0.3s ease;
  z-index: 1000;
}

.drawer.open {
  width: 100%;
  left: 0;
  top: 13.28vh;
}


.drawer-nav {
  margin-top: 50px;
  height: 70vh;
}

.drawer-nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 4vh;
}

.drawer-nav-links li {
  text-align: center;
}

.drawer-nav-links a {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  color: #6C7856;
  font-size: 2.3vh;
}

.drawer-nav-links a:hover {
  color: #4682B4;
}

@media (max-width: 400px) {
  .drawer.open {
    width: 100%;
  }

  .padre-btn-mobile{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .btn-mobile{
    width: 10vh;
    height: 2vh;
    z-index: 3000;
    margin-bottom: 0vh;
  }

}