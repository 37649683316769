/* Estilos para el componente SuccessPage */

.success-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: auto;
    min-height: 90vh;
    padding: 20px;
    margin-top: 8vh;
    text-align: start;
}

.company-logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 20vh;
    margin-bottom: 20px;
}

.h2-success {
    font-size: 3vh;
    margin-bottom: 20px;
    color: #333;
}

.p-success {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #666;
}

.download-button {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #2C3360;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
}

.download-button:hover {
    background-color: #0056b3;
}

.home-link {
    font-size: 1rem;
    color: #2C3360;
    text-decoration: none;
    transition: color 0.3s ease;
}

.home-link:hover {
    color: #0056b3;
}

/* Estilos responsivos */
@media (max-width: 768px) {
    .h2-success {
        text-align: center;
    }

    .p-success {
        text-align: center;
    }

    .download-button {
        font-size: 0.875rem;
        padding: 8px 16px;
    }

    .home-link {
        font-size: 0.875rem;
    }
}