.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.nav-links-category {
  list-style: none;
  padding: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}


.nav-links-category li {
  margin-top: 10px;
  height: auto;
  margin-right: 15px;
  justify-content: center;
  align-items: center;

  position: relative; 
  display: inline-block; 
}

.nav-links-category li a{
  font-size: 1.8vh;
  font-weight:bolder;
  letter-spacing: 0.5px;
  color: #46572A;
  text-decoration: none; 
  font-weight: 500; 
  color: white;
  transition: color 0.5s ease;
}

.nav-links-category a {
  font-size: 2vh;
  text-decoration: none;
  color: #ffffff;
  font-weight: 300;
  padding: 8px 12px;
  border-radius: 5px;
  transition: color 0.3s ease;
}

.nav-links-category a:hover {
  filter: drop-shadow(
    2px 2px 2px
  );
  background-color: #FAB71E;
  color: #6C7856; 
}
