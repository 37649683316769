/* Configuración general para todos los tamaños de pantalla */
.section-list-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50rem;
  color: #6C7856;
  font-family: "Roboto Condensed", sans-serif;
}

.filterContainerDetail {
  align-content: start;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 3rem;
  color: #6C7856;
}

.ItemDetail {
  position: relative;
  color: #6C7856;
  padding: 4vh 15vh 0;
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 5vh;
  min-height: 60vh;
  margin-bottom: 10vh;
  align-items: flex-start;
}

.content-detail-item {
  font-family: "Roboto Condensed", sans-serif;
  width: 50%;
  text-align: center;
  height: fit-content;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 2vh 0 8vh ;
  z-index: 0;
}

.sticky-gallery {
  position: -webkit-sticky;
  position: sticky;
  top: 20vh;
  z-index: 100;
  width: 60%;
  align-items: center;
  justify-content: center;
  margin: 3vh 0 -5vh -2vh;
  max-height: 100vh;
}

.galleria-container {
  flex: 0 0 80vh; 
  overflow-y: hidden;
}

.interaction-container {
  flex: 0 0 20vh; 
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.items-main-gallery {
  margin-bottom: 1vh;
  width: 100%;
  min-width: 100%;
  margin: 0;
  padding: 0;
  align-items: center;
  max-width: 100%;
  height: 50vh;
  align-content: center;
}

.items-main-gallery img {
  width: 100%;
  height: 100%;
  align-items: center;
  object-fit: contain;
}

.galleria-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.5s cubic-bezier(0.4, 0.0, 0.2, 1), filter 0.5s cubic-bezier(0.4, 0.0, 0.2, 1);
  display: block;
}

.galleria-image:hover {
  transform: scale(1.1);
  filter: brightness(1.1);
  cursor: context-menu;
}

.thumbnailTemplate {
  max-height: 10vh;
  align-items: center;
  margin-left: -2vh;
  padding: 0;
}

.p-galleria-thumbnail-item {
  display: flex;
  overflow: hidden;
  width: 50%;
  height: 100%;
  margin: 0.2vh auto;
  align-items: center;
  justify-content: center;
}

.p-galleria-thumbnail-item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.p-galleria-thumbnail-items-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.p-galleria-thumbnail-container{
  background-color: transparent;
  width: 100%;
  height: 100%;
  justify-content: center;
}


.p-galleria-thumbnail-prev{
  color: #444;
}

.p-galleria-thumbnail-next{
  color: #444;
}

.p-galleria-thumbnail-container{
  margin: 0;
  height: 100%;
}

.thumbnailTemplate img {
  object-fit: cover;
  height: 10vh;
  width: 100%;
}

.p-galleria-item-wrapper{
  width: 100%;
  align-items: center;
  justify-content: center;
  
}

.btn-container {
  height: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5vh 4vh 0;
}

.container-detalles {
  width: 100%;
  height: 100%;
  color: #6C7856;
  font-family: "Roboto Condensed", sans-serif;
  overflow: auto;
  word-wrap: break-word;
}

.detail-image {
  max-width: fit-content;
  max-height: fit-content;
  object-fit: cover;
  margin-bottom: 10px;
}

.detail-title {
  text-align: start;
  margin: 0.8vh 0;
  padding-bottom: 1vh;
  color: #444;
  text-overflow: ellipsis;
  font-weight: 500;
  height: auto;
  background-color: transparent;
  border-bottom: 1px solid #444;
}

.detail-price {
  margin: 1rem 0 4vh;
  height: 18%;
  font-size: 1.7vh;
  text-align: start;
}

.detail-description {
  margin: 8px 0;
  height: max-content;
  font-size: 1.7vh;
  max-height: auto;
  text-align: start;
  min-height: 12rem;
  white-space: pre-wrap;
  line-height: 1.8;
}

.detail-category {
  margin: 2rem 0 0 0;
  font-size: 1.7vh;
  height: 10%;
  color: #444;
}

.add-to-cart-button {
  width: 100%;
  background-color: #6C7856;
  color: #fff;
  padding: 0.55rem 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
  background-color: #46572A;
}

.custom-arrow::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
}

.custom-next-arrow::before {
  border-width: 8px 0 8px 12px;
  border-color: transparent transparent transparent #007bff;
}

.custom-prev-arrow::before {
  border-width: 8px 12px 8px 0;
  border-color: transparent #007bff transparent transparent;
}

.content-item-detail {
  margin-top: 8vh;
}

.price-and-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3vh auto;
}

.content-prices{
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1.5vh;
}

.detail-price {
  font-size: 3vh;
  color: #2C3360; 
  font-weight: bold;
  margin: 0;
}

.mercadolibre-link {
  font-size: 1.5vh;
  color: #FFB600; /* Color característico de Mercado Libre */
  text-decoration: none;
  font-weight: 500;
  background-color: #2C3360; /* Fondo para resaltar el enlace */
  padding: 0.8vh 1.8vh;
  border-radius: 5px;
  margin: -1vh 0 0 0;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.mercadolibre-link:hover {
  background-color: #FFB600; /* Cambiar el color de fondo al pasar el ratón */
  color: #2C3360; /* Cambiar el color del texto al pasar el ratón */
}

/* Adaptaciones para pantallas móviles */
@media (max-width: 1100px) {
  .ItemDetail {
    padding: 5vh 0 0;
    justify-content: center;
    flex-direction: column;
  }

  .sticky-gallery {
    max-width: 100%;
    margin: auto;
  }

  .content-detail-item {
    width: 100%;
    text-align: center;
  }

  .items-main-gallery {
    min-width: 100%;
    max-width: 100%;
    height: max-content;
    align-content: center;
  }
  
  .items-main-gallery img {
    width: 100%;
    max-width: 100%;
    height: 45.5vh;
    margin-bottom: 2vh;
  }

  .sticky-gallery {
    position: static; /* Desactiva el sticky en pantallas pequeñas */
    width: 100%;
    width: 100%;
  }

  .container-detalles {
    width: auto;
    padding: 0 3vh;
    height: 100%;
  }

  .btn-container {
    width: 100%;
  }

  .content-item-detail {
    padding: 0;
  }
  
  .galleria-image {
    object-fit: contain;
  }

  .thumbnailTemplate {
    order: 1; /* Coloca las miniaturas debajo de la imagen principal */
    background-color: transparent; /* Elimina el fondo del contenedor de miniaturas */
    margin-top: 2vh;
  }

  .items-main-gallery {
    order: 0; /* Asegura que la imagen principal esté arriba */
  }
}
