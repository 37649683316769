.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  font-family: "Roboto Condensed", sans-serif;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4); /* Capa de fondo */
  width: 100%;
  min-height: 100%;
  overflow: auto;
  transition: opacity 0.3s ease;
  z-index: 20000;
}

.modal.open {
  display: block;
  opacity: 1;
  padding: 0 1rem; /* Ajuste para evitar padding grande en pantallas pequeñas */
}

.modal-content {
  background-color: #F9F8F3;
  margin: 10% auto; /* Alinea verticalmente el modal */
  padding: 20px;
  border: 1px solid #888;
  width: 90%; /* Toma casi todo el ancho en pantallas pequeñas */
  max-width: 500px; /* Ancho máximo para pantallas grandes */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.modal-content form div {
  margin-bottom: 15px;
}

.modal-content label {
  display: block;
  font-weight: bold;
  color: #555;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin: 0px 0 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.modal-content input:focus {
  border-color: #4682B4;
}

.modal-content button {
  width: 100%;
  background-color: #9932CC;
  color: white;
  padding: 1vh 1vh;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-content button:hover {
  background-color: #0056b3;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Media queries para hacer responsive */
@media (min-width: 768px) {
  .modal-content {
    width: 70%; /* Aumenta el ancho en pantallas más grandes */
  }
}

@media (min-width: 1024px) {
  .modal-content {
    width: 50%; /* Aumenta el ancho en pantallas aún más grandes */
  }
}

@media (min-width: 1200px) {
  .modal-content {
    width: 30%; /* Ancho fijo para pantallas grandes */
  }
}
