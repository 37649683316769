.checkout-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10vh 15vh;
  min-height: 70vh;
  margin: auto auto 8rem auto;
  font-family: "Roboto Condensed", sans-serif;
}

.payment-container {
  width: 100%;
  padding-left: 8vh;
}

.payment-container h1{
  font-size: 1.8vh;
}

/* Botones seleccionar currency */

.currency-buttons {
  display: flex;
  width: 100%;
  padding: 0;
  justify-content: center;
  margin-bottom: 20px;
  gap: 2vh;
}

.btns-currency {
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #ccc;
  background-color: #f4f4f4;
  cursor: pointer;
  outline: none;
  font-weight: 500;
}

.btns-currency:hover {
  width: 100%;
  color: black;
}

.btns-currency.selected {
  background-color: #0056b3;
  color: white; 
}


/* Selected method */

.payment-method-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.container-selected{
  text-align: center;
  width: 100%;
}

.container-selected label{
  width: 100%;
}

.em-payment p:hover{
  text-decoration: underline;
  color: black;
}

.hidden-radio {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.p-stepper-panel{
  font-size: 1.7vh;
}

.container-payment-p{
  width: 100%;
  text-align: center;
}

.payment-method-select label {
  width: 100%;
  font-size: 1.8vh;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  text-align: center;
}

.selectedMethodLabel{
  width: 100%;
  text-align: center;
}

.selectedMethodLabel p{
  font-size: 1.8vh;
}

.payment-logo {
  width: 100%;
  height: auto;
  cursor: pointer;
  transition: transform 0.2s;
}

.local-money{
  text-align: start;
  font-weight: 450;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
}

.modal-content {
  position: relative;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.form-container {
  width: 100%;
  max-width: 600px;
  margin-bottom: 30px;
}

.checkout-section {
  padding: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.checkout-section h3 {
  margin-bottom: 10px;
  font-size: 2vh;
  color: #6c757d;
}

.checkout-section.open {
  max-height: 1000px;
  opacity: 1;
  padding: 20px;
}

.checkout-section.closed {
  max-height: 0;
  opacity: 0;
  padding: 0 20px;
  overflow: hidden;
}

.input-group {
  margin-bottom: 10px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.9vh;
  color: #6c757d;
}

.p-toggleable-content{
  padding-left: 0;
}

.p-button-icon{
  font-size: 1.2vh;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1.5vh;
}

.input-group label{
  font-size: 1.7vh;
}

.small-text {
  font-size: 0.8em;
  color: #6c757d;
}

.small-text.visible {
  display: block;
}

.small-text.hidden {
  display: none;
}

.containerP {
  margin-bottom: 10px;
}

.instances-container {
  width: 40%;
  height: fit-content;
  top: 50px;
}

.instances-container .instanceTitle {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #6c757d;
}

.father-instance{
  max-height: 50vh;
  overflow-y: auto;
}

.instance {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.instance img {
  width: 7.5vh;
  height: 7vh;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 4px;
}

.instance p {
  margin: 0 10px;
  font-size: 0.9em;
  color: #6c757d;
}

.price-conversion{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.prices-content{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.total-price {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  margin: 0 auto;
}

.total-price h3 {
  text-align: center;
  margin: 1vh 0;
  font-size: 2.3vh;
  color: #6c757d;
}

.em-content{ 
  color: #0056b3;
}

.em-content:hover{ 
  text-decoration: underline;
  color: #0056b3;
  cursor: pointer;
}

/*STEPPER*/
.py-4{
  width: 100%;
  padding-right: 2vh;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1350px) {
  .checkout-container {
    width: 100%;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 50px 10rem;
    margin: auto auto 8rem auto;
  }

  .instances-container {
    width: 100%;
    height: fit-content;
    margin-bottom: 10vh;
  }

  .form-container {
    width: 100%;
    max-width: 100%;
  }

  .payment-container {
    width: 100%;
    padding-left: 15vh;
  }
  
}

@media (max-width: 880px) {
  .checkout-container {
    padding: 50px 3vh;
  }
  
  .currency-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .btns-currency {
    font-size: 1.5vh;
    width: 100%;
    padding: 5px 10px;
  }

  /*STEPPER*/
  .p-stepper-separator{
    display: none;
  }

  .p-stepper-number{
    font-size: 1.7vh;
    height: 10vw;
    width: 10vw;
  }

  .p-button{
    margin: 0;
    padding: 0.8vh 2vh;
  }

  .p-stepper-toggleable-content{
    margin-left: 0;
    padding-left: 0;
  }

}