.recommended-products-container {
    padding: 20px 10vh 30px 10vh;
    background-color: whitesmoke;
}

.recommended-products-container h2 {
    margin-bottom: 20px;
    color: #444;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1;
    font-size: 3.5vh;
    padding-bottom: 10px;
    padding-left: 10vh;
    font-weight: 550;
    text-align: start;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.recommended-products {
    display: flex;
    margin: 2rem 0;
    gap: 20px;
    justify-content: space-evenly;
    overflow-x: auto;
    padding-bottom: 10px;
    scrollbar-width: none;
    -ms-overflow-style: none;  
}

.recommended-products::-webkit-scrollbar {
    display: none; 
}

.about-container{
    padding: 5vh 10vh 10vh;
}

@media (max-width: 670px) {
    .recommended-products {
        display: flex;
        flex-wrap: wrap;
        margin: 2rem 2vh;
        gap: 20px;
        justify-content: space-evenly;
        width: auto;
        overflow-x: auto;
        padding-bottom: 10px;
        scrollbar-width: none;
        -ms-overflow-style: none;  
    }

    .recommended-products-container h2 {
        padding-left: 0vh;
    }
}