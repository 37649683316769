/* styles/item.css */

.container-admin-trans {
  width: 100%;
  display: flex;
  border: none;
  height: 100%;
  min-height: 70vh;
  font-family: 'Lora', serif;
}

.sidebar {
  width: 250px;
  background-color: #f4f4f4;
  padding: 20px;
  border-right: 1px solid #ddd;
  position: relative;
  transition: transform 0.3s ease;
}

.sidebar h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #333;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1em;
}

.sidebar button:hover {
  background-color: #0056b3;
}

.sidebar button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.main-content {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

.main-content h3 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #333;
}

.hamburger {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 2vh;
  padding-left: 2vh;
  z-index: 110;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
  transition: all 0.3s ease;
}

.hamburger.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 10vh;
    left: 0;
    padding-top: 10vh;
    height: 100%;
    transform: translateX(-100%);
    z-index: 100;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .main-content {
    margin-left: 0;
    margin-top: 2vh;
  }

  .hamburger {
    display: block;
    position: fixed;
    top: 12.5vh;
    left: 0;
    z-index: 110;
  }

  .hamburger:hover {
    color: black;
  }
}
