/* Contenedor principal */
.toggle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  background-color: #f4f6f9;
  padding: 0 2vh;
  padding-top: 2vh;
}

/* Contenedor del botón */
.button-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 2vh;
}

/* Estilo de los botones */
.toggle-button {
  padding: 1vh 2vh;
  background-color: #ffffff;
  color: #333;
  border: 2px solid #ddd;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  flex: 1;
  margin: 0 10px;
}

.toggle-button:hover {
  background-color: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

.toggle-button.active {
  background-color: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

/* Contenedor de los componentes */
.component-display {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  min-height: 300px;
  width: 100%;
}

.first-component, .second-component, .fourth-component, .third-component {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.first-component {
  background-color: #dff0d8;
}

.second-component {
  background-color: #d9edf7;
}

/* Efecto de transición suave para cambiar entre componentes */
.component-display > div {
  opacity: 0;
  transform: scale(0.95);
  transition: all 0.4s ease;
}

.component-display > div:first-child {
  opacity: 1;
  transform: scale(1);
}
