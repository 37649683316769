.container-transaction{
  width: 100%;
}

.container-child-transactions{
  width: 100%;
}

.transaction-list {
    width: 100%;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
  }
  
/* Estilos para la tabla */
table {
  width: 100%;
  border-collapse: collapse; 
  margin-top: 20px;
}

.td-products{
  min-width: 20vh;
  word-wrap: break-word;
}

th, td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
  font-size: 14px;
}

th {
  background-color: #333;
  color: #ffffff;
}

td {
  background-color: #f9f9f9;
}

tr:nth-child(even) td {
  background-color: #f1f1f1;
}

tr:hover td {
  background-color: #e0e0e0; /* Color al hacer hover en la fila */
}

/* Estilos opcionales para textos */
td {
  color: #333;
}

/* Estilos para el input de búsqueda */
input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.delete-icon{
  cursor: pointer;
  color: rgb(161, 34, 24);
  transition: color 0.2s ease-in-out;
}

.delete-icon:hover{
  color: orangered;
}