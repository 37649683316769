.featured-products-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columnas en pantallas grandes */
  gap: 20px; /* Espacio entre productos */
  margin-bottom: 5vh;
  box-sizing: border-box;
  width: 100%;
  justify-content: center; /* Centra el contenido horizontalmente */
}

/* Asegura que se muestren 2 filas con 3 productos en escritorio */
@media (min-width: 1024px) {
  .featured-products-container {
    grid-template-rows: repeat(2, auto); /* 2 filas con altura automática */
    max-width: 1200px; /* Limita el ancho máximo del contenedor */
    margin: auto auto; /* Centra el contenedor horizontalmente */
  }
}

/* Adaptación para pantallas medianas */
@media (max-width: 1023px) {
  .featured-products-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columnas en pantallas medianas */
    max-width: 800px; 
    margin: 0 auto;
  }
}

/* Adaptación para pantallas pequeñas */
@media (max-width: 768px) {
  .featured-products-container {
    grid-template-columns: repeat(2, 1fr); /* 1 columna en pantallas móviles */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
  }
}

.product-link {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  text-decoration: none;
}
