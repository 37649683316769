
.carrito {
  padding: 0;
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  z-index: 55000;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.cart-title-no{
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1rem;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  
  color: #4682B4;
}

.Option{ 
  text-decoration: none;
  color: #4682B4;
  width: 100%;
  text-align: center;
  margin-top: 5rem;
}

.product-image {
  max-width: 8vh;
  max-height: 8vh;
  background-size: cover;
  object-fit: cover;
}

.product-image img{
  width: 9vh;
  background-size: cover;
  object-fit: cover;
  height: 100%;
  border-radius: 0.5vh;
}

.precio {
  display: flex;
  width: 100%;
  height: 25%;
  padding-top: 5px;
  padding-bottom: 2vh;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: start;
}

.precio h3 {
  font-size: 1.8vh;
  width: 100%;
  color: #333;
  font-weight: bold;
  margin: 10px 0;
  text-align: start;
  margin-bottom: 3vh;
}

.checkout{
  width: 100%;
}

.checkout button {
  padding: 1vh 1vh;
  width: 100%;
  font-size: 1.8vh;
  background-color: #0056b3;
  font-weight: bold;
  color: #fff;
  border: none;
  letter-spacing: 0.2vh;
  font-weight: 400;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.checkout button:hover {
  background-color: #0251ac;
}

.CartPage {
  height: 80%;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  width: 100%;
}

.CartPage ul{
  width: 100%;
  margin: 0;
  padding: 0;
}


.CartPage ul li{
  width: 100%;
  height: 12vh;
  margin: 0;
  flex-wrap: nowrap;
}

.CartItem {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
}

.lis{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: auto;
  text-align: center;
  gap: 2vh;
}

.divContent{
  width: 100%;
  height: max-content;
  text-align: start;
}

.price-text-content{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.divContent h3 {
  font-size: 1.8vh;
  font-weight: 600;
  margin-bottom: 0px;
  border: none;
  text-align: start;
}

.divContent .p-text {
  font-size: 1.8vh;
  font-weight: 600;
  margin-bottom: 5px;
}

.container-total-price{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
}

.container-total-price h3{
  width: 100%;
  display: flex;
  justify-content: center;
}

.container-total-price .first-child-total-price{
  justify-content: start;
}

.buttonClearAll {
  display: flex;
  align-items: center;
  background-color: #ff4d4d;
  color: white;
  padding: 0.5vh 1vh;
  border: none;
  border-radius: 0.5vh;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.5vh;
}

.buttonClearAll:hover {
  background-color: #ff3333;
}

.delete-cart-icon {
  margin-right: 8px;
  font-size: 1.5vh;
}

.buttonClearAll:active {
  transform: scale(0.98);
}

.buttonClear {
  font-size: 1.8vh;
  color: #E85A32;
  max-width: 200px;
  background: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.buttonClear:hover {
  color: red;
  background: none;
}
