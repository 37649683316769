/* Capa de superposición */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

/* Contenedor del cajón del carrito */
.cart-drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 50vh;
  height: 100%;
  background-color: #ffffff;
  box-shadow: -4px 0 15px rgba(0, 0, 0, 0.1);
  z-index: 55000;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
}

.cart-drawer.open {
  transform: translateX(0);
  z-index: 55000;
}


/* Botón de cerrar */
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 2.6vh;
  cursor: pointer;
  color: #999999;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: #333333;
}

/* Título del carrito */
.cart-drawer h3 {
  margin: 0;
  font-size: 2vh;
  color: #1d1d1d;
  font-weight: 600;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 15px;
  margin-bottom: 20px;
  padding-top: 1vh;
}

/* Contenedor del spinner */
.spinner-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Spinner de carga */
.spiner {
  width: 40px;
  height: 40px;
  color: #007bff;
}

/* Contenido del carrito */
.cart-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Elemento del carrito */
.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;
}

.cart-item:last-child {
  border-bottom: none;
}

.cart-item img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
}

.cart-item-details {
  flex-grow: 1;
  margin-left: 15px;
}

.cart-item-title {
  font-size: 4vh;
  color: #333;
  font-weight: 500;
  margin-bottom: 5px;
}

.cart-item-price {
  font-size: 1vh;
  color: #555;
}

/* Resumen del carrito */
.cart-summary {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-total {
  font-size: 1.2rem;
  font-weight: bold;
  color: #1d1d1d;
}

/* Botón de checkout */
.checkout-btn {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.checkout-btn:hover {
  background-color: #0056b3;
}

.container-cart{
  width: 100%;
  height: 100%;
}

/* Adaptación a pantallas más pequeñas */
@media (max-width: 768px) {
  .cart-drawer {
    max-width: 100%;
    padding: 1vh;
    margin: 0;
  }

  .close-btn {
    font-size: 1.2rem;
  }

  .cart-drawer h3 {
    font-size: 1.2rem;
  }

  .cart-item img {
    width: 50px;
    height: 50px;
  }

  .cart-item-title,
  .cart-item-price {
    font-size: 0.9rem;
  }

  .cart-total {
    font-size: 1rem;
  }

  .checkout-btn {
    font-size: 0.9rem;
    padding: 10px 15px;
  }
}
