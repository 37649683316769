.middle-part-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 7vh auto 5vh;
}

.payment-methods {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 1200px;
}

.childContainer {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  padding-bottom: 1vh;
  flex: 1 1 30%;
  text-align: center;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.icon {
  font-size: 40px;
  color: #2C3360;
  margin-right: 1vh;
  flex-shrink: 0;
}

.internChild {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.title-child {
  font-size: 2vh;
  font-weight: bold;
  color: #444;
  width: 100%;
  height: min-content;
  align-items: center;
  align-content: start;
  justify-content: center;
  text-align: center;
}

.title-first{
  padding: 0 2vh;
}

.p-first{
  padding: 0 4vh 2vh;
}

.internChild p {
  font-size: 1.7vh;
  color: #444;
  height: fit-content;
}

.intern-methods-pay{
  padding-left: 0;
  margin-left: -1vh;
}

.intern-methods-pay p{
  line-height: 1vh;
}

.accepted-currencies p {
  width: 100%;
  padding: 0.5vh 4vh 1.5vh;
  color: #444;
}

@media (max-width: 768px) {
  .middle-part-container {
    margin: 4vh auto 5vh;
  }

  .childContainer {
      flex: 1 1 100%;
  }
  
  .icon {
      font-size: 35px;
  }
  
  .title-child {
      font-size: 16px;
  }
  
  p {
      font-size: 14px;
  }
}
