.modify-product-container {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
}

h3 {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #555;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    transition: border-color 0.2s;
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #007bff;
    outline: none;
}

textarea {
    resize: vertical;
    min-height: 80px;
}

.form-group ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 20px;
}

.form-group ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 5px;
    padding: 8px 12px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.form-group ul li button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 16px;
    padding: 0 5px;
    transition: color 0.2s;
}

.form-group ul li button:hover {
    color: #0056b3;
}

.file-adj-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 4vh;
}

.file-adj-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.file-adj-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.file-adj-item a {
    flex: 1;
    color: #007bff;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-adj-item a:hover {
    text-decoration: underline;
}

.remove-file-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 1.7;
    border-radius: 4px;
}

.remove-file-button:hover {
    background-color: #c82333;
}

.file-adj-input-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.form-input {
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    flex: 1;
}

.add-url-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 8px 15px;
    cursor: pointer;
    font-size: 1.5vh;
    border-radius: 4px;
}

.add-url-button:hover {
    background-color: #218838;
}

.add-item-form {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.add-item-form input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 10px;
    transition: border-color 0.2s;
}

.add-item-form input:focus {
    border-color: #007bff;
    outline: none;
}

.add-item-form button {
    padding: 10px 15px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.add-item-form button:hover {
    background-color: #0056b3;
}

.button-group {
    display: flex;
    justify-content: space-between;
}

.button-group button {
    padding: 10px 15px;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.button-group button:hover {
    background-color: #218838;
}

.button-group button[disabled] {
    background-color: #6c757d;
    cursor: not-allowed;
}

.button-group button:last-child {
    background-color: #dc3545;
}

.button-group button:last-child:hover {
    background-color: #c82333;
}

.error {
    color: #dc3545;
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
}
