#form-checkout {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.container {
  height: 18px;
  display: inline-block;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 2px;
  padding: 1px 2px;
}
  
  .payment-container {
    width: 80%;
    text-align: center;
    font-family: "Roboto Condensed", sans-serif;
    background-color: transparent;
    margin-top: 6vh;
  }
  
  .title-checkout {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .currency-buttons {
    margin-bottom: 2rem;
  }
  
  .currency-buttons button {
    font-size: 0.8rem;
    padding: 0.5rem 1.6rem;
    justify-content: center;
    text-align: center;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .payment-btn {
    display: block;
    width: 100%;
    max-width: auto;
    margin: 0 auto;
    padding: 0.5rem;
    color: #fff;
    font-weight: bold;
    border: none;
    justify-content: center;
    text-align: center;
    background-color: transparent;
  }
  
  .paypal-btn {
    padding-bottom: 1rem;
    border-radius: 0.4rem;
    background-color: #003087;
  }

  .paypal-btn:hover {
    background-color: #003087;
  }

  .binance-btn {
    background-color: #f0b90b;
  }

  .payment-btn span {
    margin-left: 8px;
    vertical-align: middle;
    font-size: 16px;
  }
  
  .payment-btn::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background-size: cover;
  }
  
  .mercado-pago-btn{
    margin-top: -2rem;
  }

  .paypal-btn::before {
    background-image: url('https://www.paypalobjects.com/webstatic/icon/pp258.png');
  }
  
  .mercado-pago-btn::before {
    background-image: url('https://www.mercadopago.com/org-img/MP3/API/logos/mplogo-180.png');
  }
  
  .binance-btn::before {
    background-image: url('https://www.binance.com/favicon.ico');
  }

  @media (max-width: 700px) {
    .payment-container {
      width: 100%;
      left: 0;
      margin-left: -50px;
    }
    
  }