.whatsapp-button {
  position: fixed;
  bottom: 2vh;
  right: 30px;
  background-color: #25D366;
  color: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-decoration: none;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease;
  box-sizing: border-box; 
  overflow: hidden; 
}

.whatsapp-button:hover {
  transform: scale(1.1);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  background-color: #4CAF50; 
}

.whatsapp-button:active {
  transform: scale(0.95);
}

.whatsapp-button:focus {
  outline: none; 
}

.whatsapp-button i {
  transition: transform 0.2s ease;
}

.whatsapp-button:hover i {
  transform: rotate(360deg); 
}

@media (max-width: 900px) {
  .whatsapp-button {
    z-index: 100;
  }
}