.faqs-container {
  padding: 6vh 20vw;
  width: 100%;
  position: relative;
  height: auto;
  min-height: 90vh;
}
 
.faqs-list {
  max-width: 800px;
  margin: 0 auto;
  padding: 3vh;
  border-radius: 1vh;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 3vh 0;
  transition: all 0.3s ease;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #1a837c; 
}

.faq-question h4 {
  font-size: 2vh;
  margin: 0;
}

.toggle-icon {
  font-size: 1.5em;
}

.faqs-container h1{
  color: black;
  font-weight: 700;
  position: relative;
  width: 100%;
  font-size: 3vh;
  margin-top: 3.5vh;
  left: -31vh;
}

.wpp-link{
  color: blue;
}

.wpp-link:hover{
  color: rgb(117, 46, 184);
}

.faq-answer {
  max-height: 0;
  opacity: 0;
  padding-top: 2vh;
  color: black;
  font-weight: 600;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.faq-answer.show {
  max-height: 200px; /* Ajusta según el contenido */
  opacity: 1;
}

.faq-answer p {
  margin: 0;
  padding: 10px 0;
  font-size: 1em;
  color: #333;
}

.company-logo{
  margin: 0vh auto 2vh;
}

.whatsapp-link {
  padding-left: 0.6vh;
  color: #0056b3;
  text-decoration: none;
}

.whatsapp-link:hover {
  color: #2c3360;
  text-decoration: underline;
}
  
@media (max-width: 768px) {
  .faqs-container {
    padding: 6vh 2vh;
    width: 100%;
    position: relative;
    height: auto;
    min-height: 90vh;
  }

  .faqs-container h1{
    width: 100%;
    font-size: 3vh;
    margin-top: 3.5vh;
    left: 0vh;
    text-align: start;
  }

  .faq-question {
    font-size: 1.8vh;
  }

  .faq-answer {
    font-size: 1.7vh;
  }
}