.product-list {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
}

.product-list-title {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.product-position {
    display: inline-block;
    width: auto;
    padding-right: 2vh;
    font-size: 1.5vh;
    color: #666;
}

.product-list input[type="text"] {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 20px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.product-list input[type="text"]:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
    outline: none;
}

.product-list-items {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column; /* Para que los elementos se apilen verticalmente */
}

.product-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-bottom: 10px;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.product-list-item:hover {
    background-color: #f0f0f0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.child-container {
    flex: 1; /* Permite que el contenedor hijo ocupe el espacio disponible */
    margin-right: 10px; /* Espacio entre título y posición */
}

.bts-admin-list {
    display: flex;
    flex-direction: row;
    gap: 5px; /* Espacio entre botones */
}

.product-title {
    font-size: 18px;
    font-weight: 500;
    color: #444;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modify-button,
.delete-button {
    font-size: 1.5vh;
    font-weight: 500;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.modify-button {
    color: #007bff;
}

.modify-button:hover {
    color: #222222;
    text-decoration: underline;
}

.delete-button {
    color: #007bff;
}

.delete-button:hover {
    color: #222222;
    text-decoration: underline;
}

.no-products {
    font-size: 16px;
    color: #999;
    text-align: center;
    padding: 20px 0;
}

/* Media Queries para Responsividad */
@media (max-width: 600px) {
    .product-list-title {
        font-size: 20px; 
    }

    .product-title {
        font-size: 16px; 
    }

    .product-position {
        font-size: 14px; 
    }

    .product-list-item {
        flex-direction: column; 
        align-items: flex-start; 
    }

    .child-container {
        margin-right: 0; 
        margin-bottom: 5px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
        max-width: 100%; 
    }

    .bts-admin-list {
        justify-content: space-between;
        width: 100%; 
    }
}
