.padreCards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2vh; /* Espacio entre las tarjetas */
}

.padreCards > * {
    flex: 1 1 calc(50% - 16px); /* 2 columnas en pantallas móviles */
}

@media (min-width: 768px) {
    .padreCards > * {
        flex: 1 1 calc(33.33% - 16px); /* 3 columnas en pantallas medianas */
    }
}

@media (min-width: 1024px) {
    .padreCards > * {
        flex: 1 1 calc(25% - 16px); /* 4 columnas en pantallas grandes */
    }
}