
.content-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 2rem 0;
  padding: 1rem;
  background-color: #2C3360;
  border-radius: 10px;
  box-shadow: 2px 2px 4px 8px rgba(0, 0, 0, 0.147);
}

.content-img, .content-form, .content-email {
  flex: 1 1 300px;
  margin: 1rem;
}

.content-img img, .content-form img {
  max-width: 100%;
  height: auto;
  max-height: 49vh;
  border-radius: 10px;
}

.content-img img{
  max-width: 40vh;
}

.h4contact {
  font-family: "Roboto Condensed", sans-serif;

  font-size: 1.5rem;
  color: whitesmoke;
  margin-bottom: 1rem;
}

.content-email {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-email h4 {
  font-size: 1.5rem;
  color: whitesmoke;
  margin-bottom: 1rem;
}

.content-email p {
  font-size: 1rem;
  color: whitesmoke;
  margin-bottom: 1rem;
}

.content-email a {
  font-size: 1.2rem;
  color: #007bff;
  text-decoration: none;
}

.content-email a:hover {
  text-decoration: underline;
}

.wsp-link em a{
  color: white;
  text-decoration: none;
}

.wsp-link em a:hover {
  color: white;
}

@media (max-width: 768px) {
  .content-contact {
    flex-direction: column;
    padding: 1rem;
  }

  .content-img img{
    max-width: 30vh;
  }  

  .h4contact {
    font-size: 1.2rem;
  }

  .content-email h4 {
    font-size: 1.2rem;
  }

  .content-email p {
    font-size: 0.9rem;
  }

  .content-email a {
    font-size: 1rem;
  }
}