.greeting {
  text-align: center;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 2vh 0 0 0;
  margin-bottom: -10vh;
  padding: 0vh 9vh;
  gap: 1rem;
  position: relative;
}

.itemListContainer{
  width: 100%;
}

.filterContainer{
  align-content: start;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 3rem;
  top: 10vh;
  z-index: 1000;
}


.container-accordion-filter{
  width: 100%;
  padding-bottom: 1.5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1.5rem;
}

.container-items{
  width: 100%;
  padding: 0 8vh;
  margin-bottom: 13vh;
}

.categorias {
  width: 100%;
  font-family: "Roboto Condensed", sans-serif;
  padding: 0 8.5vh;
  margin-top: 2vh;
  margin-bottom: 5vh;
}

.cont-sticky{
  width: 100%;
  display: flex;
  position: sticky;
  z-index: 950;
  position: -webkit-sticky;
  flex-direction: row;
  top: 18vh;
  left: 0;
}

.filter-h3{
  width: 100%;
  border-bottom: 1px solid #46572a3b;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.categorias h3 {
  font-family: "Montserrat", sans-serif;
  text-align: start;
  font-size: 2.2vh;
  font-weight: 600;
  margin-bottom: 0.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
}

.accordion {
  border-radius: 4px;
}

.accordion-button-filters {
  width: 100%;
  color: #46572A;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  transition: background-color 0.3s ease;
}

.accordion-content input[type="checkbox"] {
  margin-right: 1vw;
  accent-color: #2c3360; 
  cursor: pointer;
}

.accordion-content label {
  color: #6c7856;
  cursor: pointer;
}


.accordion-content div:last-child {
  margin-bottom: 0;
}

/* Añadir animación a la entrada del contenido del acordeón */
.accordion-content.open div {
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.accordion-button-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 2vh;
}

.accordion-button-filters:hover{
  color: #46572A;
}

.accordion-button-filters i {
  transition: transform 0.3s ease;
  color: #46572A;
}

.accordion-button-filters h4{
  font-family: "Roboto Condensed", sans-serif;
  max-width: fit-content;
  font-size: 1.6vh;
  font-weight: 300;
  transform: translatey(9px);
}

.accordion-button-filters i.rotate {
  transform: rotate(180deg);
}

.label-search {
  font-size: 1.5vh;
  font-weight: 500;
  color: #444;
  margin-bottom: 5px;
}

.accordion-content {
  border: 1px solid #46572a3b;
  max-height: 0;
  position: absolute;
  overflow: hidden;
  display: none;
  transition: max-height 0.3s ease-out;
  padding: 0 5vh;
  text-align: start;
  visibility: hidden; 
  opacity: 0; 
}

.accordion-content.open {
  background-color: #FFFDF6;
  max-height: 60vh; 
  width: 100%;
  left: 0;
  visibility: visible; 
  opacity: 1;
  padding: 2vh 3vh 2vh;
  display: grid;
  grid-template-columns: 1fr 1fr; 
  grid-gap: 20px;
  z-index: 2000;
}

.accordion-content.open-sort {
  max-height: 60vh; 
  width: min-content;
  visibility: visible; 
  opacity: 1;
  margin-left: -23vh;
  display: grid;
  grid-template-columns: 1fr 1fr; 
  grid-gap: 20px;
  z-index: 2000;
  border: none;
}

.accordion-content.open-sort .sort-option {
  position: relative;
  cursor: pointer;
}

.accordion-button-content.button-content-sort {
  position: relative;
  cursor: pointer;
}

.accordion-button-content.button-content-sort::after {
  content: attr(data-tooltip); /* El contenido del tooltip proviene del atributo data-tooltip */
  position: absolute;
  top: -100%; /* Posicionar el tooltip arriba del botón */
  left: 50%;
  transform: translateX(-50%);
  background-color: #444; /* Color de fondo del tooltip */
  color: #fff; /* Color del texto del tooltip */
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none; /* Evitar que el tooltip interfiera con el hover */
  z-index: 1000; /* Asegurar que el tooltip esté por encima de otros elementos */
}

.accordion-button-content.button-content-sort:hover::after {
  opacity: 1;
  visibility: visible;
}

.button-content-sort{
  padding-top: 1.4vh;
}

.accordion-content a {
  color: #6C7856;
  padding: 8px 0;
  text-decoration: none;
  display: block;
}

.accordion-content a:hover{
  color: #46572A;
}

.form-search {
  width: 200%;
  margin-top: 20px;
  padding-top: 1vh;
  margin-top: 0.5em;
}

.input-name {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #444;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.input-name, 
.form-search input[type="number"] {
  width: calc(100% - 0px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-search input {
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: calc(100% - 12px);
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.input-name:focus {
  border-color: #444;
  outline: none;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

/* Paginación */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 7vh;
}

.p-button{
  border-radius: 5vh;
  padding: 0.8vh 1.8vh;
  margin: 0 5px;
}

.p-button-text {
  border-radius: 5vh;
  padding: 0.8vh 1.8vh;
  margin: 0 5px;
}

.p-button-text:hover {
  border: 1px solid #007bff;
  color: #007bff;
}

.p-button-outlined {
  border: 1px solid #007bff;
  color: #007bff;
}

.p-button-outlined:hover {
  border: 1px solid #007bff;
  color: #007bff;
}

/* Estilos del contenedor del breadcrumb */
.breadcrumb-container {
  margin-bottom: 20px;
}

@media (max-width: 1000px) {
  .greeting {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 0;
    margin: 0vh 0 0 0;
  }

  .filterContainer{
    padding-top: 2vh;
  }

  .cont-sticky{
    position: sticky;
    width: 100%;
    position: -webkit-sticky;
  }

  .accordion-content {
    border: 1px solid #46572a3b;
    max-height: 0;
    overflow: hidden;
    position: absolute; 
    transition: max-height 0.3s ease-out;
    text-align: start;
    visibility: hidden; 
    opacity: 0; 
    width: 100%;
    padding: 0;
    margin: 0;
  }
  
  .accordion-content.open {
    background-color: #FFFDF6;
    max-height: 60vh; 
    padding: 1.8vh 2vh;
    margin: 0;
    width: 100%;
    left: 0;
    visibility: visible; 
    opacity: 1;
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-gap: 20px;
    z-index: 2000;
  }
  
  .container-accordion-filter{
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 3vh;
  }

  .container-items{
    width: 100%;
    padding: 0 2vw;
  }

  .categorias{
    width: 100%;
    z-index: 1000;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;
  }

  .form-search .input-name{
    font-size: 1.7vh;
    padding: 1vh 1vh;
  }

  .categorias h3 {
    display: none;
  }

  .button-content-sort{
    padding-top: 2vh;
  }

}

