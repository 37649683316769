/* styles/SortOptions.css */
.sort-options-container {
    position: relative;
  }
  
  .sort-button {
    display: flex;
    align-items: center;
    font-size: 1.5vh;
    color: #444;
  }
  
  .sort-icon {
    margin-right: 8px;
  }
  
  .sort-options-menu {
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    z-index: 10;
    width: 200px;
    margin-top: 4px; /* Space between button and menu */
  }
  
  .sort-option {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 1.5vh;
    color: #444;
  }
  
  .sort-option:hover {
    background-color: #f0f0f0;
  }
  