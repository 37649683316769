
.containerPadre {
  display: flex;
  width: 100%;
  height: 500px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); 
}

.title-button {
  text-align: center;
  margin-bottom: 4vh;
}

.title-button h2 {
  font-size: 2.8vh;
  color: #6C7856;
  margin: 2vh 0;
  text-align: center;
}

.images-main {
  display: flex;
  width: 100%;
  height: 70vh; 
  box-sizing: border-box;
}

.img-container, .false-img {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
}

.img-container img {
  width: 100%;
  height: 100%;
  max-height: 65vh;
  object-fit: contain; 
}

.false-img {
  height: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5vh 0;
  margin-top: -5vh;
}

.div-img {
  margin-bottom: 2vh;
}

.img-minz {
  height: auto;
  mask-image: linear-gradient(black 30%, transparent);
  transform: rotate(5deg) translateY(50px);
}

.intern-false-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #2C3360;
}

.content-main h1{
  font-size: 5vh;
  color: #2C3360;
}

.content-main h2 {
  font-size: 2vh;
  color: #444;
  line-height: 1.6;
  text-align: center;
  margin: 0vh;
  padding: 0;
}

.content-false{
  margin-top: -3vh;
}

.btn-main {
  padding: 0.7vh 1.35vh;
  font-size: 1.7vh;
  margin-top: 6vh;
  background-color: #2C3360;
  width: 80%;
  max-width: 25vh;
  border: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.558); 
  transition: all 0.3s ease;
}

.btn-main:hover{
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.594); 
  transform: translateY(-1.5px);
}

.img-minz{
  max-height: 16vh;
  mask-image: linear-gradient(
    black 30%,
    transparent
  );
  z-index: 0;
  transform: translatey(30px);
}


@media (min-width: 1000px) and (max-width: 1150px) {
  .images-main {
    height: 55vh;
  }

  .img-minz{
    max-height: 10vh;
  }
}

@media (max-width: 1000px) {

  .images-main {
    flex-direction: row;
    height: auto;
    margin-top: 2vh;
  }

  .content-main h1{
    font-size: 4vh;
  }
  
  .content-main h2 {
    font-size: 2vh;
    line-height: 1.6;
    margin: 0vh;
    padding: 0;
  }

  .img-container, .false-img {
    width: 100%;
    height: 40vh; 
  }

  .content-false{
    margin-top: -7vh;
  }

  .img-container img {
    height: 100%;
  }

  .false-img {
    height: auto;
    padding: 0;
  }

  .intern-false-img {
    padding: 1vh 2.5vh;
  }

  .div-img img {
    height: fit-content;
  }
}

@media (max-width: 750px) {
  .img-container {
    display: none;
  }

  .div-img img {
    margin: 1vh auto 0;
    padding: 0 auto;
  }

  .btn-main {
    font-size: 1.7vh;
    max-width: 20vh;
  }
}