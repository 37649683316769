
.bodyContainer {
  color: #FFFDF6;
  margin: 0;
  padding: 0 10rem;
}

.partUp {
  padding: 40px 20px;
  border-bottom: 1px solid #27252495;
}


.partMiddle {
  padding: 40px 20px;
  border-bottom: 1px solid #27252495;
}


a, button {
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover, button:hover {
  color: #ffffff;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
}

span, a, p, em, li, ul{
  font-family: "Roboto Condensed", sans-serif;
}


@media (max-width: 1000px) {
  .bodyContainer {
    padding: 5px;
  }

  .partUp, .partMiddle, .partDown {
    padding: 20px 5px;
  
  }
}