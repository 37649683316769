.custom-breadcrumb .p-breadcrumb {
    background-color: transparent; 
    border: none; 
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.8vh;
    padding: 0 14vh;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limita a 2 líneas */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 2vh;
}

.custom-breadcrumb .p-breadcrumb a {
    color: #46572A; 
    text-decoration: none; 
    transition: none; 
    font-size: 1.8vh;
    padding: 0 0.3vh;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limita a 2 líneas */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* Permite el salto de línea */
}

.custom-breadcrumb .p-breadcrumb a:hover {
    color: #9932CC; 
}

.p-menuitem-icon{
    font-size: 1.5vh;
}

.p-menuitem-separator{
    font-size: 1.5vh;
}

.custom-breadcrumb .p-breadcrumb .p-breadcrumb-chevron {
    margin: 0 0.8vh; 
    color: #46572A; 
    font-size: 1.8vh;
}

.custom-breadcrumb .p-breadcrumb .p-breadcrumb-item {
    font-size: 1.8vh;
}

.p-breadcrumb-list{
    width: 100%;
}
@media (max-width: 700px) {
    .custom-breadcrumb .p-breadcrumb {
        font-size: 1.2vh;
        padding: 0;
        width: 100%;
        padding-top: 0vh;
    }
    
    .custom-breadcrumb .p-breadcrumb a {
        font-size: 1.4vh;
    }
    
    .p-menuitem-icon{
        font-size: 1.5vh;
        margin: 0;
        padding: 0;
    }
    
    .custom-breadcrumb .p-breadcrumb .p-breadcrumb-chevron {
        margin-left: 0; 
        margin-right: 0;
        color: none;
    }

    .p-breadcrumb-list{
        width: 100%;
        padding-left: 3vw;
        transform: translatey(0.8vh);
    }

  }